import React from 'react';
import { useGlobalState } from './GlobalStateContext';
import theme from '../styles/theme.js';
import cs from '../assets/images/langs/cs.svg';
import ua from '../assets/images/langs/ua.svg';
import { Grid } from '@mui/material';

const LangSwitcher = ({ unstyled }) => {
	const { globalState, setGlobalState } = useGlobalState();

	const changeLang = (lang) => {
		setGlobalState({ lang: lang });
	};

	// fancy blobs 🤤
	const rand = (min, max) => { return Math.floor(Math.random() * (max - min + 1)) + min; };
	let radii = globalState.config?.langs?.map(lang => {
		const min = 20;
		const max = 80;
		const top = rand(min, max);
		const right = rand(min, max);
		const bottom = rand(min, max);
		const left = rand(min, max);
		return `${top}% ${100 - top}% ${bottom}% ${100 - bottom}%/${left}% ${right}% ${100 - right}% ${100 - left}%`;
	});

	radii = [
		'49% 51% 37% 63%/71% 28% 72% 29%',
		'73% 27% 30% 70%/39% 26% 74% 61%',
		'26% 74% 74% 26%/79% 45% 55% 21%',
		'29% 71% 64% 36%/76% 44% 56% 24%',
		'43% 57% 50% 50%/72% 25% 75% 28%',
		'33% 67% 65% 35%/49% 54% 46% 51%',
	];

	let icons = {
		cs: cs,
		ua: ua,
	};

	if (unstyled) {
		return (
			<>
				{globalState.config?.langs?.map((lang, index) =>
					<img onClick={() => { changeLang(lang) }} key={lang} src={icons[lang] ?? cs} alt={lang} style={{ marginRight: 16 }} />
				)}
			</>
		);
	}

	return (
		<>
			{globalState.config?.langs?.map((lang, index) =>
				<div
					onClick={() => { changeLang(lang) }}
					style={{
						padding: 12,
						margin: 4,
						background: theme.palette.secondary.main,
						borderRadius: radii[index],
						transition: '2s ease'
					}}
					key={lang}
				>
					<img
						src={icons[lang] ?? cs}
						alt={lang}
						style={{ verticalAlign: 'top' }}
					/>
				</div>
			)}
		</>
	);
};

export default LangSwitcher;