import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Chapters from "./Chapters";
import MarkdownView from 'react-showdown';
import PlainPageWrapper from "./styles/PlainPageWrapper";
import Article from "./components/Article";
import { CircularProgress } from "@mui/material";

const Wiki = (props) => {
  const [state, setState] = useState({ article: null });
  const [loading, setLoading] = useState(true);

  const baseURL = "https://939olmrl.api.sanity.io/v2021-10-21/data/query/production";
  const parseJSON = (resp) => (resp.json ? resp.json() : resp);

  const checkStatus = (resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      return resp;
    }
    return parseJSON(resp).then((resp) => {
      throw resp;
    });
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer sk7wWxSBq2aXS1xGc7GpG7vwTTyEVUdQcSBFftzh7RNPtTRYoLx5Xi97e4epc9mGUf4L8JkNSrGpOlSdCOaWrcascywYDMPmW3My3K3jwJf8ZaVlVl4HltHUHqjtfxDC5DOpEryXUjmX1KEWEpn8fTig37gMImZZqtwosIt8OhuFRxBZtyIF",
  };

  useEffect(() => {
    const a = state.article;
    fetch(baseURL + "?query=" + encodeURIComponent('*[_type=="article" && slug =="' + slug + '"]'), {
      method: "GET",
      headers: headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        // console.log(resp);
        setState({
          article: resp.result[0],
        });
        setLoading(false);
      });
  }, [state.article?.title]);

  const { slug } = useParams();

  const article = state.article;

  return (
    <PlainPageWrapper>
      {/* <Chapters menu={true} lang={props.lang} setLang={props.setLang} /> */}
      <Container maxWidth="sm" className="content-container">
        {article == undefined ? (
          <>
            {loading ? <CircularProgress /> : <div>Článek nenalezen</div>}
          </>
        ) : (
          <Article article={article} />
        )}
      </Container>
    </PlainPageWrapper>
  );
}

export default Wiki;
