import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
// import MenuIcon from "@mui/icons-material/MenuIcon";

function Chapters(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, key) => {
    if (!isNaN(key)) {
      props.setPageNumber(key);
    }
    setAnchorEl(null);
  };

  let chapterTitles;
  let page;
  let currentChapterTitle;
  if (!props.menu) {
    chapterTitles = props.articles.map((article) => article.titleLocal?.[props.lang] ?? article.title);

    page = props.page;

    currentChapterTitle = chapterTitles[page - 1];
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" style={{ background: "red" }}>
          <Toolbar
            style={{
              justifyContent: props.menu ? "flex-end" : "space-between",
            }}
          >
            {props.menu ? null : (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {/* <MenuIcon /> */}
              </IconButton>
            )}
            <Button onClick={(event) => props.setLang(event, "csCZ")} style={{ color: "#fff", borderColor: "#fff" }} variant="outlined">
              CZ
            </Button>
            <Button onClick={(event) => props.setLang(event, "ukUA")} style={{ color: "#fff", borderColor: "#fff" }} variant="outlined">
              UA
            </Button>
            <img src="/logo.png" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>
      </Box>
      {props.menu ? null : (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {chapterTitles.map((title, index) => {
            if (title == currentChapterTitle) {
              return (
                <MenuItem
                  onClick={(event) => handleClose(event, index + 1)}
                  key={index + 1}
                >
                  <strong> {title}</strong>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  onClick={(event) => handleClose(event, index + 1)}
                  key={index + 1}
                >
                  {title}
                </MenuItem>
              );
            }
          })}
        </Menu>
      )}
    </div>
  );
}

export default Chapters;
