// src/GlobalStateContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import db from './db';
import defaultState from './state';
import _ from 'lodash';
import LoadingPage from '../components/LoadingPage';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
	const [globalState, setGlobalState] = useState(defaultState);
	const [loaded, setLoaded] = useState(false);

	const updateState = (newState) => {
		if (newState == null) {
			db.globalState.put({ id: 1, state: {} });
			setGlobalState({});
			return;
		}
		db.globalState.put({ id: 1, state: { ...globalState, ...removeJSX(_.cloneDeep(newState)) } });
		setGlobalState(prevState => ({ ...prevState, ...newState }));
	};

	useEffect(() => {
		db.globalState.get(1)
			.then((data) => {
				if (data) {
					setGlobalState(prevState => ({ ...prevState, ...data.state }));
				} else {
					db.globalState.put({ id: 1, state: removeJSX(_.cloneDeep(defaultState)) });
					setGlobalState(defaultState);
				}
				setLoaded(true);
			});
	}, []);

	return (
		<>
			{loaded && <GlobalStateContext.Provider value={{ globalState, setGlobalState: updateState }}>
				{children}
			</GlobalStateContext.Provider>}
			{!loaded && <LoadingPage />}
		</>
	);
};

export const useGlobalState = () => {
	const context = useContext(GlobalStateContext);
	if (!context) {
		throw new Error('useGlobalState must be used within a GlobalStateProvider');
	}
	return context;
};


function removeJSX(obj) {
	if (obj && obj.$$typeof === Symbol.for("react.element")) {
		return undefined;
	}

	if (Array.isArray(obj)) {
		return obj.map(removeJSX).filter((item) => item !== undefined);
	}

	if (typeof obj === "object" && obj !== null) {
		for (const property in obj) {
			obj[property] = removeJSX(obj[property]);

			if (obj[property] === undefined) {
				delete obj[property];
			}
		}
	}

	return obj;
}