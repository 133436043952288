import "./App.css";
import Training from "./Training";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Wiki from "./Wiki";
import { useState } from "react";
import { GlobalStateProvider } from "./utils/GlobalStateContext";
import css from './styles/global.css';

function App() {
  const [state, setState] = useState({
    lang: !localStorage.getItem("lang")
      ? "csCZ"
      : localStorage.getItem("lang"),
  });

  /* const articlesIds = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 18, 25,
  ]; */

  const setLang = (e, key) => {
    localStorage.setItem("lang", key);
    setState({ lang: key });
  };

  return (
    <GlobalStateProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Training />} />
          <Route
            path="/:slug"
            element={<Wiki lang={state.lang} setLang={setLang} />}
          />
        </Routes>
      </BrowserRouter>
    </GlobalStateProvider>
  );
}

export default App;
