const lang = {
	code: 'csCZ',
	navigation: {
		back: 'Zpět',
		prev: 'Předchozí',
		next: 'Další',
		test: 'Přejít na test',
		congrats: 'Gratulujeme k dokončení',
	},
	progress:{
		headline: 'Postup',
		pages: 'stránek',
	},
	menu:{
		home: 'Domů',
	},
};

export default lang;