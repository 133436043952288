import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from './theme';
import youklid_skoleni from '../assets/images/youklid_skoleni.png';
import BackButton from '../components/BackButton';
import Menu from '../components/Menu';
import { createTheme, IconButton, ThemeProvider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const PlainPageWrapper = (props) => {
	const navigate = useNavigate();
	const [menuOpen, setMenuOpen] = useState(false);

	const changePage = (page) => {
		setMenuOpen(false);
		props.setPage(page);
	};

	return (
		<>
			<Menu isOpen={menuOpen} close={() => setMenuOpen(false)} changePage={changePage} chapters={props.chapters} />
			<header>
				<div style={{
					background: theme.palette.secondary.main,
					padding: '8px 12px 8px 24px',
					display: 'flex',
					justifyContent: 'space-between',
					position: 'relative',
					boxShadow: '0 0 10px rgba(0,0,0,0.3)'
				}}>
					<img src={youklid_skoleni} alt="Youklid" height={30} onClick={() => navigate('/')} />
					<IconButton aria-label="menu" onClick={() => setMenuOpen(true)} size={'small'}>
						<MenuIcon />
					</IconButton>
				</div>
			</header>
			{/* context navigation */}
			<nav>
				<ThemeProvider theme={createTheme({
					palette: {
						primary: {
							main: '#004d58',
						},
					},
					// Add additional styles for the round button
					roundButton: {
						borderRadius: '50%',
					},
				})}>
					{window.location.pathname !== '/' &&
						<div style={{ padding: 16 }}>
							<BackButton />
						</div>
					}

				</ThemeProvider>
			</nav>
			<main>
				<div style={{ padding: props.padding ?? 0 }}>
					{props.children}
				</div>
			</main>
			<footer></footer>
		</>
	);
};

export default PlainPageWrapper;