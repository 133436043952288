import React from 'react';
import theme from '../styles/theme.js';

const LoadingPage = () => {
	const spinKeyframes = {
		'0%': {
			transform: 'rotate(0deg)',
		},
		'100%': {
			transform: 'rotate(360deg)',
		},
	};
	const styles = {
		width: '72px',
		height: '72px',
		animation: 'spin 1s linear infinite',
	};
	return (
		<div style={{
			width: '100%',
			height: '100vh',
			background: theme.palette.primary.main,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-loader-2"
				width="72"
				height="72"
				viewBox="0 0 24 24"
				strokeWidth="3"
				fill="none"
				style={styles}
			>
				<path d="M0 0h24v24H0z" fill="none" />
				<path d="M12 3a9 9 0 1 0 9 9" stroke={theme.palette.secondary.main} />
				<style>{`
					@keyframes spin {
						${Object.entries(spinKeyframes).map(([key, value]) => `${key} { ${Object.entries(value).map(([property, val]) => `${property}: ${val};`).join(' ')} }`).join(' ')}
					}
				`}</style>
			</svg>
		</div>
	);
};

export default LoadingPage;