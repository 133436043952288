const lang = {
	code: 'ukUA',
	navigation: {
		back: 'Назад',
		prev: 'Попередній',
		next: 'Далі',
		test: 'Перейти до тесту',
		congrats: 'Вітаю з завершенням',
	},
	progress: {
		headline: 'Прогрес',
		pages: 'сторінок',
	},
	menu: {
		home: 'Головна',
	},
};

export default lang;