import { Button, IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLang from '../utils/I18n';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const BackButton = () => {
	const navigate = useNavigate();
	const back = () => {
		navigate(-1);
	};
	const t = useLang();

	return (
		<Button
			variant='outlined'
			icon={<NavigateBeforeIcon />}
			onClick={back}
			size='small'
			color='primary'
			style={{ borderRadius: 100 }}
		>
			<NavigateBeforeIcon />
			{t.navigation.back}
		</Button>
	);
};

export default BackButton;