import React, { useEffect, useState } from 'react';
import theme from '../styles/theme';
import youklid_skoleni from '../assets/images/youklid_skoleni.png';
import { Button, Collapse, createTheme, Grid, IconButton, ThemeProvider } from '@mui/material';
import useLang from '../utils/I18n';
import { Link, useNavigate } from 'react-router-dom';
import LangSwitcher from '../utils/LangSwitcher';
import { useGlobalState } from '../utils/GlobalStateContext';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Menu = ({ isOpen, close, changePage, chapters, setPage }) => {
	const navigate = useNavigate();
	const t = useLang();

	const { globalState, setGlobalState } = useGlobalState();
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		setShowContent(true);
	});

	let generatedItems = [];

	if (chapters) {
		generatedItems = chapters.map((article, index) => ({
			//path: '/' + article.slug,
			path: '/',
			title: article?.titleLocal[t.code],
			bold: globalState?.page - 1 == index,
		}));
	}

	const items = [
		// {
		// 	path: '/',
		// 	title: t.menu.home,
		// },
		...generatedItems,
	];

	// console.log(items)

	const phoneModal = () => {
		setGlobalState({
			alert: {
				title: 'Opravdu chcete volat?',
				content: <>
					<span>Dbejte na to že ve firmě preferujeme psaní přes Whatsapp. Chcete stejně zavolat?</span>{' '}
					{/* <a key={'phone'} href="tel:+420775405345" style={{ margin: '24px 0' }}><Button type='text' size='large'><PhoneFilled style={{ transform: 'scaleX(-1)' }} />Zavolat na 775 405 345</Button></a> */}
					<a href="tel:+420775405345">Zavolat na 775405345</a>
				</>,
				severity: 'warning',
			}
		});
	};

	if (!isOpen) return null;

	return (
		<div style={{ position: 'fixed', inset: 0, zIndex: 1000, background: theme.palette.background }}>

			<header>
				<Grid container justifyContent={'space-between'} style={{
					background: theme.palette.secondary.main,
					padding: '8px 12px 8px 24px',
					position: 'relative',
					boxShadow: '0 0 10px rgba(0,0,0,0.3)'
				}}>
					<Grid item>
						<img src={youklid_skoleni} alt="Youklid" height={30} onClick={() => navigate('/')} />
					</Grid>
					<Grid item>
						<Grid container>
							<LangSwitcher unstyled={true} />
							<IconButton aria-label="close-menu" onClick={close} size={'small'}><CloseIcon /></IconButton>
						</Grid>
					</Grid>
				</Grid>
			</header>
			<Collapse in={showContent}>
				<div style={{ padding: 24, position: 'absolute', left: 0, bottom: 0, right: 0, maxHeight: 'calc(100vh - 48px)', overflowY: 'auto' }}>
					<style>
						{`
							.menuLink{
								text-transform: initial;
								font-size: 14px;
							}
						`}
					</style>

					<ThemeProvider theme={createTheme({
						palette: {
							primary: {
								main: '#004d58',
							},
						},
						components: {
							MuiButtonBase: {
								styleOverrides: {
									root: {
										padding: '4px!important',
									},
								},
							},
						}
					})}>
						<Grid container flexDirection={'column'} justifyContent={'flex-end'} alignItems={'center'} gap={0}>
							{items.map((item, index) => (
								<div key={index} style={{ border: item.bold ? '1px solid #004d58' : 'none', borderRadius: 100 }}>
									{item.path === '/' ?
										<Button key={index} onClick={() => changePage(index + 1)} type='default' size='large'><span className='menuLink'>{item.title}</span></Button>
										:
										<>
											{item.path.startsWith('http') || item.path.startsWith('//') ?
												<a key={index} href={item.path} target="_blank" rel="noopener noreferrer">
													<Button type='text' size='large'><span className='menuLink'>{item.title}</span></Button>
												</a>
												:
												<Link key={index} to={item.path}><Button type='text' size='large'><span className='menuLink'>{item.title}</span></Button></Link>
											}
										</>}
								</div>
							))}
							{/* <Grid container justifyContent={'center'} alignItems={'center'} gap={2} style={{ marginTop: 24 }}>
								<a aria-label="Zavolejte" href="tel:+420775405345">
									<Button variant='outlined' style={{ borderRadius: 100 }}>
										<PhoneIcon />
										<span className='menuLink'>Telefon</span>
									</Button>
								</a>

								<a aria-label="Chat on WhatsApp" href="https://wa.me/+420775405345" rel="noopener noreferrer" target="_blank">
									<Button variant='contained' style={{ borderRadius: 100 }}>
										<WhatsAppIcon />
										<span className='menuLink'> Whatsapp</span>
									</Button>
								</a>
							</Grid> */}
						</Grid>
					</ThemeProvider>
				</div>
			</Collapse >
		</div >
	);
};

export default Menu;