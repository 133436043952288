import React from 'react';
import useLang from '../utils/I18n';
import MarkdownView from 'react-showdown';

const Article = ({ article }) => {
	const t = useLang();

	return (
		<div key={article.order}>
			<h1>{article.titleLocal?.[t.code] ?? article.title}</h1>
			<style>
				{`
					img {
						max-width: 100%;
					}
				`}
			</style>
			<MarkdownView
				markdown={article.contentLocal?.[t.code] ?? article.content}
				options={{ tables: true, emoji: true }}
			/>
		</div>
	);
};

export default Article;