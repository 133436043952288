import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { Button, CircularProgress, Container, createTheme, Grid, LinearProgress, ListItem, ThemeProvider, Typography } from "@mui/material";
import Chapters from "./Chapters";
import PlainPageWrapper from "./styles/PlainPageWrapper";
import useLang from "./utils/I18n";
import { useGlobalState } from "./utils/GlobalStateContext";
import LangSwitcher from "./utils/LangSwitcher";
import Article from "./components/Article";
import TheoryTest from "./components/TheoryTest";

const Training = (props) => {
  const baseURL = "https://939olmrl.api.sanity.io/v2021-10-21/data/query/production";
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const { globalState, setGlobalState } = useGlobalState();
  const [loading, setLoading] = useState(true);
  const t = useLang();

  // console.log('page: '+page);

  useEffect(() => {
    // setPage(localStorage.getItem("page") ? parseInt(localStorage.getItem("page")) : 1);
    setPage(globalState.page ?? 1);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer sk7wWxSBq2aXS1xGc7GpG7vwTTyEVUdQcSBFftzh7RNPtTRYoLx5Xi97e4epc9mGUf4L8JkNSrGpOlSdCOaWrcascywYDMPmW3My3K3jwJf8ZaVlVl4HltHUHqjtfxDC5DOpEryXUjmX1KEWEpn8fTig37gMImZZqtwosIt8OhuFRxBZtyIF",
    };

    fetch(baseURL + "?query=" + encodeURIComponent('*[_type=="article"]'), {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) return response;
      })
      .then(result => result.json())
      .then(data => {
        setArticles(data.result
          .filter((article) => article.partOfMainLine)
          .sort((a, b) => a.order - b.order)
        );
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    setGlobalState({ page: page });
    localStorage.setItem("page", page);
    window.scrollTo(0, 0);
  }, [page]);

  const next = () => {
    setPage(page => Math.min(articles.length, page + 1));
  };

  const prev = () => {
    setPage(page => Math.max(1, page - 1));
  };

  const setPageNumber = (newPage) => {
    setPage(newPage);
  };

  const finish = () => {
    setPage(articles.length + 1);
  };

  // Print errors if any
  if (error) {
    return <div>An error occured: {error.message}</div>;
  }

  const article = articles[page - 1];
  const title = (article?.titleLocal?.[t.code] ?? article?.title);
  document.title = "Youklid - " + (title ?? 'školení');

  return (
    <PlainPageWrapper chapters={articles} setPage={setPage}>
      {/* <Chapters articles={articles} page={page} setPageNumber={setPageNumber} lang={t.code} /> */}
      <Container maxWidth="sm" className="content-container">
        {page === 1 && <Grid container justifyContent={'center'}><LangSwitcher /></Grid>}
        {page <= articles.length &&
          <ThemeProvider theme={createTheme({
            palette: {
              primary: {
                main: '#8c5cbc',
              },
            },
          })}>
            <Grid container justifyContent={'space-between'} >
              <Typography>{t.progress.headline}</Typography>
              <Typography>{page}/{articles.length} {t.progress.pages}</Typography>
            </Grid>
            <div style={{ height: 8 }} />
            <LinearProgress variant="determinate" value={(page) / articles.length * 100} style={{ height: 10, borderRadius: 100 }} />
          </ThemeProvider>
        }

        {page === articles.length + 1 ? (
          <TheoryTest />
        ) : page === articles.length + 2 ? (
          <div> <h1> {t.navigation.congrats} </h1> </div>
        ) : article == undefined ? (
          <>
            {loading ? <CircularProgress /> : <div>Chyba se stránkováním</div>}
          </>
        ) : (
          <Article article={article} />
        )}

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {page != 1 &&
            <Grid item>
              <Button style={{ color: "#004d58", borderColor: "#004d58" }} variant="outlined" onClick={prev}>
                {t.navigation.prev}
              </Button>
            </Grid>
          }

          <div> {/* keeps buttons on sides */} </div>

          {page < articles.length &&
            <Grid item>
              <Button style={{ backgroundColor: "#004d58" }} variant="contained" onClick={next}>
                {t.navigation.next}
              </Button>
            </Grid>
          }

          {page == articles.length &&
            <Grid item>
              <Button style={{ backgroundColor: "#004d58" }} variant="contained" onClick={finish}>
                {t.navigation.test}
              </Button>
            </Grid>
          }
        </Grid>
      </Container>
    </PlainPageWrapper>
  );

}

export default Training;
