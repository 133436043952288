//* included with
//* import theme from '../styles/theme.js';

const theme = {
	palette: {
		white: '#ffffff',
		background: '#f2fcfa',
		primary: {
			main: '#004d58',
			light: '#007a8b',
			dark: '#002025',
		},
		secondary: {
			main: '#a1e7d7',
			light: '#caf1e8',
			dark: '#78ddc6',
		},
		accent: {
			main: '#8c5cbc',
			light: '#a680cb',
			dark: '#7343a2',
		},
		success: {
			main: '#2e7d32',
			light: '#edf7ed',
		},
		warning: {
			main: '#ed6c02',
			light: '#fff4e5',
		},
		danger: {
			main: '#A30000',
			light: '#fdeded',
		},
		info: {
			main: '#0288d1',
			light: '#e5f6fd',
		},
	},
	typography: {
		fonts: {
			primary: 'agrandir, montserrat',
		},
	}
};

export default theme;