import React from 'react';
import useLang from '../utils/I18n';

const TheoryTest = () => {
	const t = useLang();

	return (
		<div>
			{t.code === "csCZ" ? (
				<iframe
					src="https://docs.google.com/forms/d/e/1FAIpQLSeuwAoYhWp0FXWmqc3x2jnm2lbYB7L_zdTmO84MFk7jVqKCFA/viewform?embedded=true"
					style={{
						width: "100%",
						height: "80vh",
					}}
					frameBorder="0"
					marginHeight="0"
					marginWidth="0"
					title="form-cz"
				>
					Načítání…
				</iframe>
			) : null}
			{t.code === "ukUA" ? (
				<iframe
					src="https://docs.google.com/forms/d/e/1FAIpQLSeuwAoYhWp0FXWmqc3x2jnm2lbYB7L_zdTmO84MFk7jVqKCFA/viewform?embedded=true"
					style={{
						width: "100%",
						height: "80vh",
					}}
					frameBorder="0"
					marginHeight="0"
					marginWidth="0"
					title="form-ua"
				>
					Завантаження…
				</iframe>
			) : null}
		</div>
	);
};

export default TheoryTest;