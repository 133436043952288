import { useGlobalState } from './GlobalStateContext';
import cs from '../langs/cs';
import ua from '../langs/ua';

const useLang = () => {
	const { globalState, setGlobalState } = useGlobalState();

	const lang = globalState.lang ?? globalState.defaultLang;

	switch (lang) {
		case 'cs': return cs;
		case 'ua': return ua;
		default: return cs;
	}
};

export default useLang;